/*eslint-disable*/
import React, { useEffect, useState } from "react";
// @material-ui/core components
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

export default function QuantityPanier() {

  const [isCountItemPaner, setCountItemPaner] = useState([]);

  const countItemPaner = async () => {

    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetCountPaner.php`)
        .then(res => {
          //console.log(res.data.countpanerdata)
          setCountItemPaner(res.data.countpanerdata.qte);
          return;
        })
    } catch (error) { throw error; }
  };
  useEffect(() => {
    countItemPaner();
  }, []);
  return (
    <>
      {isCountItemPaner}
    </>
  );
}
