/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// @material-ui/icons

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import FormControlLabel from "@material-ui/core/FormControlLabel";

import Radio from "@material-ui/core/Radio";

import DefaultInput from "components/ToggleVisibility/DefaultInput.js";

import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function SectionProductPage() {

  const [isTypeProduct, setTypeProduct] = useState([]);

  const getTypeProduct = async (ids) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetTypeProduct.php`)
        .then(res => {
          setTypeProduct(res.data.groupeproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const [selectedEnabled, setSelectedEnabled] = useState();
  sessionStorage.setItem("setSelectedEnabled", selectedEnabled);

  const classes = useStyles();
  useEffect(() => {
    getTypeProduct();
  }, []);

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised_)}>
        <div className={classes.container_}>
          <label className={classes.title}>FAMILLE </label>
          <ToggleVisibilityAddInput>
            <DefaultInput
              valueString={'Groupe'}//POUR CREER UNE FAMILLE SUPPLEMENTAIRE
            />
          </ToggleVisibilityAddInput>
          <GridContainer justify="center_">
            {isTypeProduct.map((item) => (
              <GridItem xs={12} sm={6} md={3} key={item.id_grp}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedEnabled === item.id_grp}
                        onChange={() => setSelectedEnabled(item.id_grp)}
                        value={item.id_grp}
                        name="radio button enabled"
                        aria-label={item.id_grp}
                        icon={
                          <FiberManualRecord className={classes.radioUnchecked} />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.textBlue,
                      root: classes.labelRoot
                    }}

                    label={item.design_grp}
                  />
                </div>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
