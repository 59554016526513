/*eslint-disable*/
import React, { useEffect, useState } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import classNames from "classnames";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";

// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import Email from "@material-ui/icons/Email";

// import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import { Link } from "react-router-dom";
import { TextField } from '@material-ui/core/';
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import accountPageStyle from "assets/jss/material-kit-pro-react/views/accountPageStyle.js";

import image from "assets/img/smartphone.jpg";

const useStyles = makeStyles(accountPageStyle);

export default function LoginPage() {


  const classes = useStyles();
  const history = useHistory();
  const [userInfo, setuserInfo] = useState({
    name: '',
    surname: '',
    enterprise: '',
    batiment: '',
    telephone: '',
    number: '',
    road: '',
    street: '',
    cp: '',
    email: '',
    pass: '',
  });

  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }
  const [message, resultAddAccount] = useState([]);

  const [isListRegion, setListRegion] = useState([]);
  const [regionSelect, setRegionSelect] = useState([]);
 
 //sessionStorage.setItem("getRegionSelect", regionSelect);

 

  const [accountCreated, setAccountCreated] = useState(false);

  const loadRegion = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetRegion.php`)
        .then(res => {
          setListRegion(res.data.listeregiondata);
          return;
        })
    } catch (error) { throw error; }
  };


  const submitUser = async (event) => {
    console.log(regionSelect)
    try {
      event.preventDefault();
      event.persist();
      if (userInfo.pass.length < 8) {
        resultAddAccount('Votre mot de passe est trop court, 8 caractères minimum');
        return false;
      }
      if (userInfo.pass !== userInfo.confirm) {
        resultAddAccount('Confirmation du mot de passe en erreur');
        return false;
      }
      if (isNaN(userInfo.cp) || userInfo.cp.length !== 5) {
        resultAddAccount('Code Postal en erreur');
        return false;
      }
      if (isNaN(userInfo.telephone) || userInfo.telephone.length !== 10) {
        resultAddAccount('Numéro de Téléphone en erreur');
        return false;
      }
      Axios.defaults.withCredentials = true;


      Axios.post(`${ApiConfig.baseUrl}/Controls/CreateUser.php`, {
        username: userInfo.name,
        surname: userInfo.surname,
        enterprise: userInfo.enterprise,
        batiment: userInfo.batiment,
        telephone: userInfo.telephone,
        number: userInfo.number,
        road: userInfo.road,
        street: userInfo.street,
        cp: userInfo.cp,
        region: regionSelect,
        useremail: userInfo.email,
        userpass: userInfo.pass,
        //idowner: sessionStorage.getItem("getIdOwnerSelect"),
      })
        .then(res => {
          //console.log(res.data.userdata);
          if (res.data.userdata['success'] === true) {
            //history.push("/catalogue-page");
            setAccountCreated(true);
            resultAddAccount('');
          } else {
            resultAddAccount(res.data.userdata['msg']);
          }
          return;
        })
    } catch (error) { throw error; }
  };
  useEffect(() => {
    loadRegion();
  }, []);
  return (
    <div>
      <Header
        absolute
        color="white"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form} onSubmit={submitUser}>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Créer un compte {sessionStorage.getItem("getNameOwnerSelect")}</h4>
                  </CardHeader>

                  <CardBody signup>

                    <h4 className={classNames(classes.textValidation, classes.textBolder)}>Renseignez les infos utiles</h4>
                    <TextField className={classes.input}
                      type="text"
                      id="Nom"
                      name="name"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Nom"
                      placeholder="Votre nom"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField className={classes.input}
                      type="text"
                      id="surname"
                      name="surname"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Prénom"
                      placeholder="Votre prénom"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField className={classes.input}
                      type="text"
                      id="enterprise"
                      name="enterprise"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Entreprise"
                      placeholder="Votre entreprise"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField className={classes.input}
                      type="text"
                      id="batiment"
                      name="batiment"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Nom du batiment"
                      placeholder="Batiment"
                      autoComplete="off"
                    />
                    <br /><br />
                    <TextField className={classes.input}
                      type="text"
                      id="telephone"
                      name="telephone"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Téléphone"
                      placeholder="Votre Numéro"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <h4 className={classNames(classes.textValidation, classes.textBolder)}>Adresse de votre entreprise</h4>
                    <TextField className={classes.tinput}
                      type="text"
                      id="number"
                      name="number"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Numéro"
                      placeholder="Numéro"
                      autoComplete="off"
                      required
                    />
                     <br /><br />
                    <TextField className={classes.input}
                      type="text"
                      id="road"
                      name="road"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Voie"
                      placeholder="Voie"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField className={classes.input}
                      type="text"
                      id="street"
                      name="street"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Ville"
                      placeholder="Ville"
                      autoComplete="off"
                      required
                    />
                     <br /><br />
                    <TextField className={classes.input}
                      type="text"
                      id="cp"
                      name="cp"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Code postal"
                      placeholder="Code Postal"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      Région
                      <Select defaultValue=""
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={regionSelect}
                        onChange={(event) => { setRegionSelect(event.target.value) }}

                        inputProps={{
                          name: "regionSelect",
                          id: "regionSelect"
                        }}
                      >
                        {isListRegion.map((item) => (
                          <MenuItem key={item.id_reg}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={item.id_reg}
                          >
                            {item.departement}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br /><br />
                    <h4 className={classNames(classes.textValidation, classes.textBolder)}>Vos identifiants</h4>
                    <TextField
                      type="email"
                      id="email"
                      name="email"
                      variant="standard"
                      onChange={onChangeValue}
                      label="E-mail professionnel"
                      placeholder="Saisir un e-mail"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField
                      type="pass"
                      id="pass"
                      name="pass"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Mot de passe"
                      placeholder="Saisir un mot de passe"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField
                      type="pass"
                      id="confirm"
                      name="confirm"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Confirmez"
                      placeholder="Confirmez le mot de passe"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                  </CardBody>
                  <div className={classes.textCenter}>
                    {accountCreated === false ?
                      <>
                        <Button type="submit" color="info" size="sm">Enregistrer</Button>
                        <br />
                        <h6 className={classes.textValidation}>Soumis à validation de l'administrateur sous 24h</h6>
                      </>
                      :
                      <>
                        <Link to="/catalogue-page">
                          <Button round className={classes.buttonEflex}>
                            Votre compte est créé
                          </Button>
                        </Link>
                        <br />
                        <h6 className={classes.textValidation}>Il est en attente de validation par notre administrateur,</h6>
                        <h6>Reconnectez-vous dans 24h avec vos identifiants.
                          {/* vous allez recevoir un mail sur l’e-mail que vous avez renseigné */}
                          </h6>
                      </>
                    }
                    <br />
                    <h6 className={classes.textWarning}>{message}</h6>
                  </div>
                </form>
                <br />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <br /><br />
      </div>
    </div>
  );
}
