import React from "react";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


export default function ToggleVisibilityUpdate({ product }) {

  if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {//A CHANGER sessionStorage.getItem("statut_clt") === 'modeAdmin'
  return (
    <>
    <Link to="/updateProduct-page">
 
    <Button round color="primary"
                      startIcon={<ArrowBackIcon />}
                      >
                          Modifier
                        </Button>
  
    </Link>
   </>   
  );
}
else return false;
}

