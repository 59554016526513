
import React, { useEffect, useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
import SaveIcon from '@material-ui/icons/Save';

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
import { TextField } from '@material-ui/core/';

const useStyles = makeStyles(styles);

export default function Compteur(props) {
  const classes = useStyles();
  const { id_std } = props

console.log(props)
  const [messageEmptyLocalization, resultEmptyLocalization] = useState([]);
  const [messageConfirmAction, resultMessageConfirmAction] = useState([]);

 
  const [nivfinResult, setNivfinResult] = useState([]);


  const nivfinResultOnLoad = function (id_std) {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetQteStd.php`, {
        id_std: id_std,
        idLoc: sessionStorage.getItem("idLoc"),
      })
        .then(res => {
          //console.log(res.data.query)
          //setNivfin2Result(res.data.qteStdData.nivfin2);
          setNivfinResult(res.data.qteStdData.nivfinstock);
          return;
        })
    } catch (error) { throw error; }
  }

  const [qte, setNivfin] = useState();
  //console.log(qte)
 const handleClick = (event) => {
    setNivfin(event.target.value);
  };
 

 

    const setQuantityItem = async (id_std) => {
   // console.log(id_std)
    if (sessionStorage.getItem("statut_clt") === 'modeAdmin' || sessionStorage.getItem("statut_clt") === 'modeClient') {

      if (sessionStorage.getItem("idLoc") && id_std) {

        try {
          Axios.defaults.withCredentials = true;
          Axios.post(`${ApiConfig.baseUrl}/Controls/AddArticleQte.php`, {
            id_std: id_std,
            qte_std: qte,
            idLoc: sessionStorage.getItem("idLoc"),
          })
            .then(res => {
              // console.log(res.data);

              if (res.data.success === true) {
                setNivfinResult(res.data.itemCountData.nivfinstock);
                nivfinResultOnLoad(id_std);
                resultMessageConfirmAction(res.data.itemCountData.qteStd + ' element(s) ');// A voir si pour afficher un message apres validation
                resultEmptyLocalization('');
              }
              return;
            })
        } catch (error) { throw error; }
      }
      else {
        resultEmptyLocalization('Définissez la localisation');
      }
    }
    else {
      resultEmptyLocalization('Inventaire en consultation seulement');
    }
  };

 /*  useEffect(() => {
    nivfinResultOnLoad(id_std);//charge le catalogue par default
  }, []); */

  return <>
    <div className={classes.textCenter}>
      <br />
      <h6 className={classes.textWarning}>{messageEmptyLocalization}</h6>
      <h6 className={classes.textBlue}>{messageConfirmAction}</h6>

    </div>
    <div className={classes.mlAuto}>
      <span className={classNames(classes.price, classes.priceNew)}>
        Total: {nivfinResult}
      </span>
    </div>
    <GridContainer>
      <GridItem xs={4} sm={4} md={4}>
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        <span key={2}>
          <div className={classes.buttonGroup}>
            Article: {qte}
            <br />
            {sessionStorage.getItem("statut_clt") === 'modeAdmin' || sessionStorage.getItem("statut_clt") === 'modeClient' ?
              <>
                <TextField className={classes.input}
                  type="text"
                  onChange={handleClick}
                  value={qte}
                />
                <Button
                  color="info"
                  size="sm"
                  round
                  onClick={() =>setQuantityItem(id_std)}>
                  <SaveIcon />
                </Button></>
              :
              <><hr></hr></>
            }
          </div>
        </span>
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
      </GridItem>
    </GridContainer>
  </>
}