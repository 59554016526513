/*eslint-disable*/
import React, { useState } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { useHistory } from "react-router-dom";

import SectionGroupPage from "views/PresentationPage/Sections/SectionGroupCheckBox.js";

//import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Search from "@material-ui/icons/Search";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import { TextField } from '@material-ui/core/';
import { FaSearch } from 'react-icons/fa';

import formPageStyle from "assets/jss/material-kit-pro-react/views/presentationSections/formPageStyle.js";

const useStyles = makeStyles(formPageStyle);

export default function SectionSearchGroup({setcatalogue,resultCountArticle}) {
  

  const history = useHistory();

  const classes = useStyles();

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

   const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const [isNameParameter, setNameParameter] = useState({
    nameParameter: '',
  });
  const [message, noSearchResult] = useState([]);

  const onChangeValue = (e) => {
    setNameParameter({
      ...isNameParameter,
      [e.target.name]: e.target.value
    });
  }

  const getResearch = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;

      Axios.post(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`, {
        designation: isNameParameter.nameParameter,
      })
        .then(res => {
                      //console.log(res.data.query);
          if (res.data.success === true) {
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Article(s)");
            noSearchResult('');
            //console.log(res.data.catalogueresearchdata);
        
          }
          else {
            noSearchResult('Aucun résultat, reformulez votre recherche');
            resultCountArticle('Aucun produit trouvé');

            return;
          }


        })
    } catch (error) { throw error; }
  };

  return (
    <div>
      <ScrollAnimation animateIn="animate__fadeInUp">
        <div id="FormSearch" className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                <form className={classes.form} onSubmit={getResearch}>
                    <CardBody>
                    <TextField className={classes.input}
                      type="text"
                      id="nameParameter"
                      name="nameParameter"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Désignation..."
                      placeholder="Saisir le nom"
                      autoComplete="off"
                      required
                    />
                    {'\u00A0'}  {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'}
                     <Button justIcon  href="/" color="info" round onClick={getResearch}>
                      <Search className={classes.icons} />
                    </Button> <h6 className={classes.textWarning}>{message}</h6> 
                    </CardBody>                
                </form>  
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </ScrollAnimation>
    </div>
  );
}
