/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import DefaultInput from "components/ToggleVisibility/DefaultInput.js";

import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function SectionColorPage() {

  const getColorProduct = async (ids) => {
     try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetColorProduct.php`)
        .then(res => {
          setColorProduct(res.data.colorproductdata);
          return;
        })
    } catch (error) { throw error; } 
  };

  const [isColorProduct, setColorProduct] = useState([]);
  const [colorSelect, setColorSelect] = useState([]);

  sessionStorage.setItem("getColorSelect", colorSelect);
  const classes = useStyles();
  
  useEffect(() => {
    getColorProduct();
  }, []);

  return (
      <div className={classNames(classes.main, classes.mainRaised_)}>
        <div className={classes.container_}>
        <label className={classes.title}>COULEUR </label>       
          <ToggleVisibilityAddInput>
          <DefaultInput 
           valueString={'Couleur'}
           />
        </ToggleVisibilityAddInput>
          <FormControl
            fullWidth
            className={classes.selectFormControl}
          >
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={colorSelect}
              onChange={event => setColorSelect(event.target.value)}
              inputProps={{
                name: "colorSelect",
                id: "colorSelect"
              }}
            >
              {isColorProduct.map((item) => (
                <MenuItem key={item.id_col}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={item.id_col}
                >
                  {item.design_col}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
  );
}
