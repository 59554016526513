/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
//import PresentationPage from "views/PresentationPage/PresentationPage.js";
import ConectPage from "views/ConectPage/ConectPage.js";
import AccountPage from "views/AccountPage/AccountPage.js";
import CataloguePage from "views/CataloguePage/CataloguePage.js";
import LogoutPage from "views/LogoutPage/LogoutPage.js";
//import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import ExportPage from "views/ExportPage/ExportPage.js";
import AddProductPage from "views/AddProductPage/AddProductPage.js";
import UpdateProductPage from "views/AddProductPage/UpdateProductPage.js";
import UpdateProductLocalisation from "views/AddProductPage/UpdateProductLocalisation.js";
import LocalisationPage from "views/LocalisationPage/LocalisationPage.js";
import ValidationPage from "views/AccountPage/ValidationPage.js";



import contact from "views/IframePage/Contact.js";
import account from "views/IframePage/Account.js";
import productlist from "views/IframePage/Productlist.js";
import orderlist from "views/IframePage/Orderlist.js";
import orderlistSelf from "views/IframePage/OrderlistSelf.js";
import createUsers from "views/IframePage/CreateUsers.js";
import orderlistReport from "views/IframePage/OrderlistReport.js";
import chargeArticle from "views/IframePage/ChargeArticle.js";
import treatmentcartorder from "views/IframePage/Treatmentcartorder.js";



// import ComponentsPage from "views/ComponentsPage/ComponentsPage.js";


const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/* <Route path="/components" component={ComponentsPage} /> */}
         {/* <Route path="/catalogue-page" component={CataloguePage} /> */}
              {/*  <Route path="/" component={PresentationPage} /> */}
                    {/* <Route path="/contact-page" component={ContactUsPage} /> */}


      <Route path="/logout-page" component={LogoutPage} />
      <Route path="/account-page" component={AccountPage} />
      <Route path="/addProduct-page" component={AddProductPage} />
      <Route path="/updateProduct-page" component={UpdateProductPage} />
      <Route path="/updateProductLocalisation-page" component={UpdateProductLocalisation} />
      <Route path="/export-page" component={ExportPage} />   
      <Route path="/localisation-page" component={LocalisationPage} />
      <Route path="/validation-page" component={ValidationPage} />

     
     
     <Route path="/conect-page" component={ConectPage} />
     <Route path="/account-page" component={account} />
     <Route path="/productlist-page" component={productlist} />
     <Route path="/orderlist-page" component={orderlist} />
     <Route path="/orderlistSelf-page" component={orderlistSelf} />

     <Route path="/createUsers-page" component={createUsers} />
     <Route path="/orderlistReport-page" component={orderlistReport} />
     <Route path="/chargeArticle-page" component={chargeArticle} />
     <Route path="/treatmentcartorder-page" component={treatmentcartorder} /> 
     <Route path="/contact-page" component={contact} />

     <Route path="/" component={CataloguePage} />

    </Switch>
  </Router>,
  document.getElementById("root")
);
