/*eslint-disable*/
import React, { useEffect, useState } from "react";
// @material-ui/core components
//import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
//import { ExportToExcel } from './ExcelFile';
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
//import InputAdornment from "@material-ui/core/InputAdornment";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Email from "@material-ui/icons/Email";
// import Favorite from "@material-ui/icons/Favorite";
// import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
//import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
//import CustomInput from "components/CustomInput/CustomInput.js";

import logoutPageStyle from "assets/jss/material-kit-pro-react/views/logoutPageStyle.js";

import image from "assets/img/stockage.jpg";

const useStyles = makeStyles(logoutPageStyle);



export default function exportPage() {

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="BOURSE DU MOBILIER"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form}>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Télécharger</h4>
                  </CardHeader>
                  <CardBody signup>
                  </CardBody>
                  <div className={classes.textCenter}>
                    <a color="info"
                      href={`${ApiConfig.baseUrl}/inventaireResult.php?&id=${sessionStorage.getItem("getIdOwnerSelect")}`}
                      target="_blank"
                      className={classes.textBlue}
                    >
                      <i className="fa fa-download" />&nbsp;&nbsp;Tableau Excel
                    </a>
                    &nbsp;&nbsp;&nbsp;
                    <a color="info"
                      href={`${ApiConfig.baseUrl}/CatalogueInventairePdf.php?id=${sessionStorage.getItem("getIdOwnerSelect")}`}
                      target="_blank"
                      className={classes.textBlue}
                    >
                      <i className="fa fa-download" />&nbsp;&nbsp;PDF
                    </a>
                  </div>
                  <div className={classes.textCenter}>
                    <Link to="/">
                      <Button simple color="info" size="lg">
                        Retour
                      </Button>
                    </Link>
                  </div>

                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <br /><br />
      </div>
    </div>
  );
}
