import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
//import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
/* import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Cached from "@material-ui/icons/Cached";
import Subject from "@material-ui/icons/Subject";
import Check from "@material-ui/icons/Check";
// core components
import Accordion from "components/Accordion/Accordion.js";
 */
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
//import Clearfix from "components/Clearfix/Clearfix.js";



/* import photo1 from "assets/img/ia/bourseauxmobiliers.jpeg";
 */import photo1 from "assets/img/ia/bourseauxmobiliers.jpeg";
import photo2 from "assets/img/echangemobilier.jpg";
import { Link } from "react-router-dom";

//import dg3 from "assets/img/dg3.jpg";
//import dg1 from "assets/img/dg1.jpg";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionProducts() {


  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ behavior: 'smooth' });
  };

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>

        <GridContainer>
         <GridItem md={6} sm={6}>
            <Card className={classes.shadowForCard_}>
              <div
                aria-label="eFLEX"
                role="img"
                style={{
                  backgroundColor: '#fff',
                  backgroundImage: `url(${photo1})`,
                  backgroundPosition: '50%',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  height: '0',
                  paddingBottom: '80%',
                  width: '100%',
                }}
              />
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  La Bourse aux Mobiliers

                </h6>

                <h3 className={classes.cardTitle}>
                  La Bourse aux mobiliers, facilitez vos échanges de mobiliers
                </h3>

                <p className={classes.description}>
                </p>
                <Button round className={classes.buttonEflex}
                  onClick={() => smoothScroll('bottomSection')}
                ><ArrowDownwardIcon />
                </Button>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={6} sm={6}>
            <Card className={classes.shadowForCard_}>
              <div
                aria-label="eFLEX"
                role="img"
                style={{
                  backgroundColor: '#fff',
                  backgroundImage: `url(${photo2})`,
                  backgroundPosition: '50%',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  height: '0',
                  paddingBottom: '80%',
                  width: '100%',
                }}
              />
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  Inscription
                </h6>

                <h3 className={classes.cardTitle}>
                  Créez votre compte et commencez à échanger vos mobiliers !
                </h3>

                <div className={classes.pullRight}>
                <Link to="/account-page" className={classes.navLink}>
                <Button round className={classes.buttonEflex}


>
<ArrowForwardIcon />
</Button>
        </Link>
        </div>
               
              </CardBody>
            </Card>
          </GridItem>
          
        </GridContainer>
      </div>
      <div id="bottomSection"></div>
    </div>
  );
}
