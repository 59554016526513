import React, { useEffect, useState, useRef } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
//import { Link } from "react-router-dom";

import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
//import Badge from "components/Badge/Badge.js";
//import Tooltip from "@material-ui/core/Tooltip";
// @material-ui icons
//import Favorite from "@material-ui/icons/Favorite";
//import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
//import Remove from "@material-ui/icons/Remove";
//import Add from "@material-ui/icons/Add";
import RotateRightIcon from '@material-ui/icons/RotateRight';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import ArrowForwardIcon from '@material-ui/icons/ArrowUpwardOutlined';
//import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
import styles from "./sectionCatalogueStyle.js";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";

import Accordion from "components/Accordion/Accordion.js";
import Default from "components/ToggleVisibility/Default.js";

import ToggleVisibility from "components/ToggleVisibility/ToggleVisibility.js";
//import ToggleVisibilityMove from "components/ToggleVisibility/ToggleVisibilityMove.js";
import ToggleVisibilityUpdate from "components/ToggleVisibility/ToggleVisibilityUpdate.js";
//import ToggleVisibilityOrder from "components/ToggleVisibility/ToggleVisibilityOrder.js";
import ToggleVisibilitySwitch from "components/ToggleVisibility/ToggleVisibilitySwitch.js";


//import Checkbox from "@material-ui/core/Checkbox";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Check from "@material-ui/icons/Check";


//import SectionFamille from "views/AddProductPage/Sections/SectionFamille.js";
//import SectionColor from "views/AddProductPage/Sections/SectionColor.js";
//import SectionMateriau from "views/AddProductPage/Sections/SectionMateriau.js";
//import SectionFinition from "views/AddProductPage/Sections/SectionFinition.js";
//import SectionFournisseur from "views/AddProductPage/Sections/SectionFournisseur.js";
//import SectionMarque from "views/AddProductPage/Sections/SectionMarque.js";
//import SectionFabricant from "views/AddProductPage/Sections/SectionFabricant.js";

//import SectionLocalisation from "views/LocalisationPage/SectionLocalisation.js";

//import SectionLatestOffers from "views/EcommercePage/Sections/SectionLatestOffers.js";
import SectionProducts from "views/EcommercePage/Sections/SectionProducts.js";
import SectionSearchProduct from "views/CataloguePage/Sections/SectionSearchProduct.js";


//import Compteur from "views/CompteurPage/CompteurPage.js";

//import ListeInventory from "views/LocalisationPage/ListeInventoryPage.js";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
//import DefaultInput from "components/ToggleVisibility/DefaultInput.js";
//import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";

import TooltipFavoris from "views/CompteurPage/TooltipFavoris.js";
//import ImageZoom from "react-image-zooom";

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const useStyles = makeStyles(styles);

export default function SectionCatalogue() {

  const classes = useStyles();

  const [messageCountArticle, resultCountArticle] = useState([]);

  const [isShowInformation, setShowInformation] = useState(2);

  const position = useRef(null);

  const [iscatalogue, setcatalogue] = useState([]);

  const [isitem, setitem] = useState([]);

  const [images, showPicture] = useState([]);

  // const [messageEmptyInventory, resultEmptyInventory] = useState([]);

  //FAVORIS
  const [checked, setChecked] = useState(false);

  const handleChange = async (event) => {
    setChecked(event.target.checked);
    // console.log(event.target.checked);
    try {
      Axios.defaults.withCredentials = true;

      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`, {
        byFav: event.target.checked,
      })
        .then(res => {
          if (res.data.success === true) {
           // console.log(res.data.query);
            // setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Article(s)");
          }
          else {
            //console.log(res.data.query);
            //resultCountArticle(res.data.recordCount + " Produit(s)");
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })
    } catch (error) { throw error; }

  }

  const resetIndexData = () => {
    sessionStorage.setItem("ctr_index", 0);//seteur a 0 dans image gallery
  }


  const [isGroupProduct, setGroupProduct] = useState([]);

  const [groupSelect, setGroupSelect] = useState();

  const [isGenreProduct, setGenreProduct] = useState([]);
  const [genreSelect, setGenreSelect] = useState();
  
  const [isListRegion, setListRegion] = useState([]);
  const [regionSelect, setRegionSelect] = useState();

  /* const [isModeleProduct, setModeleProduct] = useState([]);
  const [modeleSelect, setModeleSelect] = useState(); */

 
  /*FILTRE*/
  const getGroupProduct = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetGroupProduct.php`)
        .then(res => {
          setGroupProduct(res.data.groupproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const getGenreProduct = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetGenreProduct.php`)
        .then(res => {
          //console.log(res.data.genreproductdata)
          setGenreProduct(res.data.genreproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const getRegion = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetRegion.php`)
        .then(res => {
          //console.log(res.data.listeregiondata)
          setListRegion(res.data.listeregiondata);
         
          return;
        })
    } catch (error) { throw error; }
  };

/*   const getModeleProduct = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetModeleProduct.php`)
        .then(res => {
          //console.log(res.data.modeleproductdata)
          setModeleProduct(res.data.modeleproductdata);
          return;
        })
    } catch (error) { throw error; }
  }; */
  /*CATALOGUE*/
  const getCatalogueData = async () => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.get(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`)
        .then(res => {
          if (res.data.success === true) {
            // console.log(res.data.query);
            // setShowInformation(2);
           // console.log(res.data.cataloguedata)
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Article(s)");
            setChecked(false);//pour vider la checbox apres le reset
           /*  setGroupSelect();
            setGenreSelect();
            setRegionSelect(); */
          }
          else {
            //console.log(res.data.query);
            //resultCountArticle(res.data.recordCount + " Produit(s)");
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  
  const getCatalogueDataReset = async () => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.get(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`)
        .then(res => {
          if (res.data.success === true) {
            // console.log(res.data.query);
            // setShowInformation(2);
           // console.log(res.data.cataloguedata)
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Article(s)");
            setChecked(false);//pour vider la checbox apres le reset
           // setGroupSelect();//charge le filtre Famille  
           // getModeleProduct(); 
          }
          else {
            //console.log(res.data.query);
            //resultCountArticle(res.data.recordCount + " Produit(s)");
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const getCatalogueDataGroup = async (id_grp) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`, {
        id_grp: id_grp,
      })
        .then(res => {

          if (res.data.success === true) {
            //console.log(res.data.query);
            setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Article(s)");
          }
          else {
            //console.log(res.data.query);
            //resultCountArticle(res.data.recordCount + " Produit(s)");
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getCatalogueDataGenre = async (id_gen) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`, {
        id_gen: id_gen,
      })
        .then(res => {
          if (res.data.success === true) {
           // console.log(res.data.query);
            setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Article(s)");
          }
          else {
           // console.log(res.data.query);
            // resultCountArticle(res.data.recordCount + " Produit(s)");
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })

    } catch (error) { throw error; }
  };

 /*  const getCatalogueDataModele = async (id_mod) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`, {
        id_mod: id_mod,
      })
        .then(res => {
          if (res.data.success === true) {
           // console.log(res.data.query);
            setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Article(s)");
          }
          else {
           // console.log(res.data.query);
            // resultCountArticle(res.data.recordCount + " Produit(s)");
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })
    } catch (error) { throw error; }
  }; */


  const getCatalogueDataRegion = async (id_reg) => {
   // console.log(id_reg)
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`, {
        id_reg: id_reg,
      })
        .then(res => {
          if (res.data.success === true) {
         //  console.log(res.data.query);
            setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Article(s)");
          }
          else {
           //console.log(res.data.query);
            // resultCountArticle(res.data.recordCount + " Produit(s)");
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })

    } catch (error) { throw error; }
  };

 

  /*CHARGE les caracteristiques du produit*/
  const getItemData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetItem.php`, {
        id_std: id,
      })
        .then(res => {
          //console.log(res.data.query)
          if (res.data.success === true) {
            setShowInformation(1);
            setitem(res.data.itemdata);//data avec l'image en position 1
            getPicturesData(id);//photo
            smoothScrollTarget("showItemId");
          }
          return;
        })
    } catch (error) { throw error; }
  };

  //CONSTRUIT LE TABLEAU IMAGE GALLERY
  const getPicturesData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetPictures.php`, {
        id_std: id,
      })
        .then(res => {
          if (sessionStorage.getItem("id_std")) {
            sessionStorage.removeItem("id_std");
          }
          sessionStorage.setItem("id_std", res.data.id_std);
          showPicture(res.data.picturedata);
          return;
        })
    } catch (error) { throw error; }
  };


  const getItemRotate = async (id) => {
    //console.log(position.current.state.currentIndex)
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/RotatePicture.php`, {
        id_std: id, position: position.current.state.currentIndex,
      })
        .then(res => {
          // console.log(res.data)
          getCatalogueData();//recharge le catalogue par default et prends la mise à jour de l'image
          getItemData(id);
          return;
        })
    } catch (error) { throw error; }
  };

  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ behavior: 'smooth' });
  };


  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScrollTarget = (target) => {
    var targetScroll = document.getElementById(target);
    //scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
    targetScroll.scrollIntoView({ block: 'start' });
  };

  /* const smoothScrollClickTarget = (e, target) => {
    e.preventDefault();
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  }; */

  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  useEffect(() => {
    getCatalogueData();//charge le catalogue par default
    getGroupProduct();//charge le filtre Famille  
    getGenreProduct();
    getRegion(); 
    //getModeleProduct(); 
    }, []);

  return (
    <div id="showCatalogueId" className={classes.section}>
   <SectionProducts />
       <SectionSearchProduct setcatalogue={setcatalogue} resultCountArticle={resultCountArticle} />      
   
      <div className={classes.container}>
           <GridContainer className={classes.pickSize}>
            <GridItem md={2} sm={2}>
              <div className={classes.containerForm}>
                <label>Famille</label>
                 <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Select defaultValue=""
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={groupSelect}
                    onChange={(event) => { getCatalogueDataGroup(event.target.value) }}
                   
                    inputProps={{
                      name: "groupSelect",
                      id: "groupSelect"
                    }}
                  >
                    {isGroupProduct.map((item) => (
                      <MenuItem key={item.id_grp}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item.id_grp}
                      >
                        {item.name_grp}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </GridItem>
            
            <GridItem md={2} sm={2}>
              <div className={classes.containerForm}>
                <label>Type</label>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Select defaultValue=""
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={genreSelect}
                    onChange={(event) => { getCatalogueDataGenre(event.target.value) }}
                    inputProps={{
                      name: "genreSelect",
                      id: "genreSelect"
                    }}
                  >
                    {isGenreProduct.map((itemG) => (
                      <MenuItem key={itemG.id_gen}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={itemG.id_gen}
                      >
                        {itemG.name_gen}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </GridItem>

            <GridItem md={2} sm={2}>
              <div className={classes.containerForm}>
                <label>Région</label>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Select defaultValue=""
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={regionSelect}
                    onChange={(event) => { getCatalogueDataRegion(event.target.value) }}
                    inputProps={{
                      name: "regionSelect",
                      id: "regionSelect"
                    }}
                  >
                    {isListRegion.map((itemR) => (
                      <MenuItem key={itemR.id_reg}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={itemR.id_reg}
                      >
                        {itemR.departement}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </GridItem>

            {/* <GridItem md={2} sm={2}>
              <div className={classes.containerForm}>
                <label>Modèle</label>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Select defaultValue=""
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={modeleSelect}
                    onChange={(event) => { getCatalogueDataModele(event.target.value) }}
                    inputProps={{
                      name: "modeleSelect",
                      id: "modeleSelect"
                    }}
                  >
                    {isModeleProduct.map((itemM) => (
                      <MenuItem key={itemM.id_mod}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={itemM.id_mod}
                      >
                        {itemM.name_mod}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </GridItem> */}
         
            <GridItem md={1} sm={1}>
              <div className={classes.smallTextSize} >
                {sessionStorage.getItem("statut_clt") === 'modeAdmin' || sessionStorage.getItem("statut_clt") === 'modeClient' ?
                  <>{"Favoris"}
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleChange}
                      className={classes.inputtypecheckbox}
                    />
                  </>
                  :
                  <></>
                }
              </div>
            </GridItem>
            <GridItem md={2} sm={2}>
              <div className={classNames(classes.elementRight, classes.pullRight)}>

                <Button round className={classes.buttonBleu}

                  onClick={() => getCatalogueDataReset()}

                >reset<RotateLeftIcon />
                </Button>
              </div>
            </GridItem>
          </GridContainer>
       
 <br />
        <br />
       
        <GridContainer justify="center">
          <GridItem
            xs={12}
            sm={12}
            md={6}
            className={classes.gridItem}
          >
          </GridItem>
        </GridContainer>
       
        <ToggleVisibility>
          <Default />
        </ToggleVisibility>
        <div className={classes.textCenter}>
          <br />

          <h3 className={classNames(classes.textMLV, classes.textBolder)}>{messageCountArticle}</h3>
        </div>
        <GridContainer>
          <div id='topCatalogue' />
          {iscatalogue.map((item) => (
            <GridItem xs={12} sm={6} md={4} key={item.id_std}>
              <div id={"cat_" + item.id_std} />
              {/*  <br /> <br /><br /> */}
              <Card product plain className={classes.shadowForCard}>
                <CardHeader image plain className={classes.textCenter}>
                  <Zoom>
                    <div
                      aria-label="MLV"
                      role="img"
                      style={{
                        backgroundColor: '#fff',
                        backgroundImage: `url(${ApiConfig.baseUrl}/images/${item.image})`,
                        backgroundPosition: '50%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        height: '0',
                        paddingBottom: '80%',
                        width: '100%',
                      }}
                    />
                  </Zoom>

                  {/*    <div className={classNames(classes.textCenter, classes.imageFist)}
                    style={{
                      // backgroundImage: `url(${ApiConfig.baseUrl}/images/thumbs/${item.image})`,
                      backgroundImage: `url(${ApiConfig.baseUrl}/images/${item.image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                      opacity: "1"
                    }}
                  >
                    <a href="#showItemId" onClick={() => { getItemData(item.id_std); resetIndexData(); }}>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </a>
                  </div> */}


                  {/*  <div className={classNames(classes.textCenter, classes.imageFit)}>

                 <img className={classes.imageFit2} src={`${ApiConfig.baseUrl}/images/${item.image}`} />
                      <ImageZoom className={classes.imageFit2} src={`${ApiConfig.baseUrl}/images/${item.image}`} zoom="200" /> 
                    
                  </div>  */}

                </CardHeader>
                <CardBody className={classNames(classes.textCenter, classes.elementPadding)} plain>
                  <a href="#showItemId" onClick={() => { getItemData(item.id_std); resetIndexData(); }}>
                    <p className={classNames(classes.textEflex, classes.textBolder)}>REF : {item.refArticle}  
                     <span className={classNames(classes.textEflex, classes.textBolder)}>   {item.categorie}</span> 
                    </p>

                    <h4 className={classNames(classes.textMLV, classes.textBolder)}>{item.libelle}</h4>
                  </a>
                  <p className={classes.textBolder}>Famille: {item.famille}</p>
                  <p className={classes.textBolder}>Type: {item.design_gen}</p>

                  <p className={classes.textBolder}>
                    {item.unitheight !== '' ? <>H : {item.unitheight} </> : <></>}
                    {item.unitwidth !== '' ? <> L : {item.unitwidth} </> : <></>}
                    {item.unitdeph !== '' ? <> P : {item.unitdeph} </> : <></>}

                 
                  </p>
                     <div className={classes.textCenter}> 
                      <span className={classNames(classes.textEflex, classes.textBolder)}>
                        {item.dispoArticle} Article(s)
                      </span>
                      </div>
                      {/* <h4 className={classNames(classes.line, classes.textBolder)}>{item.selectFormatPriceBefore}</h4>
                      <h4 className={classNames(classes.textEflex, classes.textBolder)}>{item.selectFormatPrice}</h4> */}
                     

                      <TooltipFavoris
                        id_std={item.id_std}
                        checkFav={item.checkFav}
                      />

                    {/* </div> */}
                
                </CardBody>
                <CardFooter plain>
                  <p className={classes.textJustify}>
                    {/*  {item.description} */}
                  </p>
                </CardFooter>
              </Card>
              {/* </div> */}
            </GridItem>
          ))}
        </GridContainer>

        <div id="showItemId" className={classes.spacePaddingL} ></div>
        {isShowInformation === 1 ? (
          <div className={classes.productPage}>
            {isitem.map((itemP) => (
              <div className={classes.ItemPage} key={itemP.id_std}>
                {/* <div className={classNames(classes.section_, classes.sectionGray)}> */}
                <div className={classNames(classes.container, classes.shadowForBox_)}>
                  {/*                   <div className={classNames(classes.container, classes.mainRaised)}>
 */}

                  <GridContainer>
                    <GridItem md={6} sm={6}>

                      <ImageGallery
                        showFullscreenButton={false}
                        showPlayButton={false}
                        startIndex={parseInt(sessionStorage.getItem("ctr_index"))}
                        ref={position}
                        items={images}
                        showThumbnails={true}
                        renderLeftNav={(onClick, disabled) => {
                          return (
                            <button
                              className='image-gallery-left-nav'
                              disabled={disabled}
                              onClick={onClick}
                            />
                          );
                        }}
                        renderRightNav={(onClick, disabled) => {
                          return (
                            <button
                              className='image-gallery-right-nav'
                              disabled={disabled}
                              onClick={onClick}

                            />
                          );
                        }}
                        renderCustomControls={(onClick) => {
                          if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
                            return (
                              <Button
                                color="transparent"
                                size="sm"
                                round
                                onClick={() => getItemRotate(sessionStorage.getItem("id_std"))} >
                                <RotateRightIcon /></Button>
                            );
                          }
                        }}
                      />

                    </GridItem>
                    <GridItem md={6} sm={6}>
                      <h2 className={classNames(classes.textMLV, classes.textBolder)}>{itemP.libelle}</h2>
                      <h3 className={classes.title}>{itemP.groupe}</h3>                      
                      <h4 className={classNames(classes.textEflex, classes.textBolder)}>{itemP.genre}</h4>
                      <h4 className={classes.title}>Référence {itemP.ref}</h4>

                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Description",
                            content: (
                              <p> {itemP.description}
                              </p>
                            )
                          }
                        ]}
                      />
                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Informations",
                            content: (
                              <ul>
                                <li>Hauteur en cm : {itemP.unitheight}</li>
                                <li>Largeur en cm : {itemP.unitwidth}</li>
                                <li>Profondeur en cm : {itemP.unitdeph}</li>
                                <li>Volume en m3 : {itemP.unitvolume}</li>
                              </ul>
                            )
                          }
                        ]}
                      />
                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Complément",
                            content: (
                              <ul>
                                <li>Materiau : {itemP.materiau}</li>
                                <li>Couleur : {itemP.couleur}</li>
                                <li>Finition : {itemP.finition}</li>
                                <li>Fabricant : {itemP.fabricant}</li>
                              </ul>
                            )
                          }
                        ]}
                      />
                       <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Localisation",
                            content: (
                              <ul>
                                <li>Numéro : {itemP.numero}</li>
                                <li>Voie : {itemP.voie}</li>
                                <li>Ville : {itemP.ville}</li>
                                <li>Code postal : {itemP.codepostal}</li>
                                <li>Bâtiment : {itemP.batiment}</li> 
                                <li>Région : {itemP.region}</li>
                              </ul>
                            )
                          }
                        ]}
                      />
                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Disponible",
                            content: (
                              <ul>
                                <li>Quantité : {itemP.dispo}</li>
                               </ul>
                            )
                          }
                        ]}
                      />
                     <hr />
          



                      <GridContainer className={classes.pullRight_}>

                        <ToggleVisibilityUpdate
                          product={itemP.id_std}
                        >
                        </ToggleVisibilityUpdate>
                        <Button round className={classes.buttonEflex}
                          endIcon={<ArrowForwardIcon />}
                          onClick={() => smoothScroll('cat_' + itemP.id_std)}
                        >
                        </Button>
                        <ToggleVisibilitySwitch
                          product={itemP.id_std}
                        >
                        </ToggleVisibilitySwitch>

                        {/*    <ToggleVisibilityMove></ToggleVisibilityMove> */}




                      </GridContainer>
                      <br /><br />
                      <br />
                    </GridItem>
                  </GridContainer>
                  {/*                   </div>
 */}
                </div>
                {/*   </div> */}
              </div>
            )
            )}
          </div>) : (<div></div>)}

        <br /><br />
        {/*  <hr /> */}
        <br /><br />
        <GridContainer className={classes.pullRight}>
          <Button round className={classes.buttonEflex}
            endIcon={<ArrowForwardIcon />}
            onClick={() => { smoothScroll('topCatalogue'); setShowInformation(2); }}// ON CLICK 2 FUNCTIONS
          >
          </Button>
        </GridContainer>
      </div>
    </div>
  );
}
