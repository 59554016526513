/*eslint-disable*/
import React, { useState, useRef, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
//import Tooltip from "@material-ui/core/Tooltip";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";


// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import CachedIcon from '@material-ui/icons/Cached';
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import ArtTrack from "@material-ui/icons/ArtTrack";
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ArrowForwardIcon from '@material-ui/icons/ArrowUpwardOutlined';
import RotateRightIcon from '@material-ui/icons/RotateRight';


//import AccountCircle from "@material-ui/icons/AccountCircle";

//import Remove from "@material-ui/icons/Remove";
//import Add from "@material-ui/icons/Add";
//import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Header from "components/Header/Header.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";

import CardBody from "components/Card/CardBody.js";
import Muted from "components/Typography/Muted.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";
import { TextField } from '@material-ui/core/';
import { TextareaAutosize } from '@material-ui/core/';
//import Footer from "components/Footer/Footer.js";
//import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
//import MenuItem from "@material-ui/core/MenuItem";
//import Select from "@material-ui/core/Select";
// import CardHeader from "components/Card/CardHeader.js";
// import Badge from "components/Badge/Badge.js";
//import useLocalStorage from "hooks/useLocaleStorage.js";

import SectionProductPage from "views/AddProductPage/Sections/SectionProductPage.js";//fammile a selectionner
import SectionColor from "views/AddProductPage/Sections/SectionColor.js";
import SectionSliderUnit from "views/PresentationPage/Sections/SectionSliderUnit.js";
import SectionLocalisation from "views/LocalisationPage/SectionLocalisation.js"
import Compteur from "views/CompteurPage/QuantityPage.js"

import SectionModele from "views/AddProductPage/Sections/SectionModele.js";
import SectionMateriau from "views/AddProductPage/Sections/SectionMateriau.js";
import SectionFinition from "views/AddProductPage/Sections/SectionFinition.js";
import SectionFabricant from "views/AddProductPage/Sections/SectionFabricant.js";

//import SectionFournisseur from "views/AddProductPage/Sections/SectionFournisseur.js";
//import SectionMarque from "views/AddProductPage/Sections/SectionMarque.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

import Accordion from "components/Accordion/Accordion.js";
import ImageGallery from "react-image-gallery";


//import FormControlLabel from "@material-ui/core/FormControlLabel";

//import Radio from "@material-ui/core/Radio";


//import InputAdornment from "@material-ui/core/InputAdornment";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import Email from "@material-ui/icons/Email";
//import Face from "@material-ui/icons/Face";
//import CustomInput from "components/CustomInput/CustomInput.js";
//import { description } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(profilePageStyle);

export default function ProfilePage() {


  const [addcatalogueInfo, setaddcatalogueInfo] = useState({
    designationLabel: '',
    // descriptionArea: '',
  });

  const onChangeValue = (e) => {
    setaddcatalogueInfo({
      ...addcatalogueInfo,
      [e.target.name]: e.target.value
    });
  }


  const [isColor, setColor] = useState();
  //console.log(isColor)

  const onChangeColor = (event) => {
    setColor(event.target.value);
  }


  const classes = useStyles();

  const [id_std_create, setIdStdCreate] = useState([]);
  const [nameStd, setNameStd] = useState('');

  const [messageAdd, resultAddProduct] = useState([]);
  const [messageAddWarm, resultAddProductWarm] = useState([]);

  const [messageDescription, setMessageDescription] = useState([]);

  const [messageUpdateComplement, resultUpdateProductComplement] = useState([]);
  const [messageUpdate, resultUpdateProduct] = useState([]);

  const [messageCheck, resultUpdateCheck] = useState(1);
  //const [messageErrorCheck, resultErrorCheck] = useState([]);


  const [isArticle, setArticle] = useState([]);


  const history = useHistory();


  const [images, showPicture] = useState([]);
  const position = useRef(null);

  const resetIndexData = () => {
    sessionStorage.setItem("ctr_index", 0);//seteur a 0 dans image gallery
  }

  const smoothScrollTarget = (target) => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ block: 'start' });
  };



  const [text, setText] = useState('');

  const [islibelle, setlibelle] = useState('');

  const onChangeLibelle = (event) => {
    setlibelle(event.target.value);
    setText(event.target.value);
  };

  const addToCatalogue = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      if (sessionStorage.getItem("id_std")) {
        sessionStorage.removeItem("id_std");
      }
      Axios.defaults.withCredentials = true;
      if (sessionStorage.getItem("setSelectedEnabled") === 'undefined') {
        resultAddProductWarm('la désignation famille est manquante');
        return false;
      }
      if (islibelle === '') {
        resultAddProductWarm('Le libellé est manquant');
        return false;
      }
      if (islibelle.length > 50) {
        resultAddProductWarm('Le libellé est trop long');
        return false;
      }
      /*   if (sessionStorage.getItem("getColorSelect") == '') {
          resultAddProductWarm('la couleur est manquante');
          return false;
        } */
      /*  if (!sessionStorage.getItem("setHeightUnit")) {
         resultAddProductWarm('la hauteur est manquante');
         return false;
       }
       if (!sessionStorage.getItem("setWidthUnit")) {
         resultAddProductWarm('la longueur est manquante');
         return false;
       }
       if (!sessionStorage.getItem("setDepthUnit")) {
         resultAddProductWarm('la profondeur est manquante');
         return false;
       }
  */
      Axios.post(`${ApiConfig.baseUrl}/Controls/AddToCatalogue.php`, {
        productGroup: sessionStorage.getItem("setSelectedEnabled"),
        productColor: isColor,
        productHeight: sessionStorage.getItem("setHeightUnit"),
        productWidth: sessionStorage.getItem("setWidthUnit"),
        productDeph: sessionStorage.getItem("setDepthUnit"),
        productDesignation: islibelle,
      })
        .then(res => {
          //console.log(res.data.query1)
          if (res.data.productdata['success'] === true) {
            resultAddProductWarm('');
            resultAddProduct('Votre Article est ajouté');

            sessionStorage.setItem("id_std", res.data.productdata['id_std']);
            setIdStdCreate(res.data.productdata['id_std']);
            setNameStd(res.data.productdata['productDesignation']);

            if (sessionStorage.getItem("setHeightUnit")) {
              sessionStorage.removeItem("setHeightUnit");
            }
            if (sessionStorage.getItem("setWidthUnit")) {
              sessionStorage.removeItem("setWidthUnit");
            }
            if (sessionStorage.getItem("setDepthUnit")) {
              sessionStorage.removeItem("setDepthUnit");
            }
            return;
            //getItemData();
          }
          else {
            resultAddProduct('');
            resultAddProductWarm('La désignation du produit est manquante');
          }
        })
    } catch (error) { throw error; }
  };

  const addDescription = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      sessionStorage.getItem("id_std")
      Axios.defaults.withCredentials = true;
      if (!addcatalogueInfo.descriptionArea) {
        setMessageDescription('la description est manquante');
        return false;
      }
      // console.log(addcatalogueInfo.descriptionArea)

      Axios.post(`${ApiConfig.baseUrl}/Controls/AddDescription.php`, {
        productDescription: addcatalogueInfo.descriptionArea,
        productId: sessionStorage.getItem("id_std"),

      })
        .then(res => {
          //console.log(res.data.query)
          if (res.data.productdescriptiondata['success'] === true) {
            setMessageDescription('Description enregistrée');
            //getItemData();
          }
          else {
            setMessageDescription('La description du produit est manquante');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  //CONSTRUIT LE TABLEAU IMAGE GALLERY
  const getPicturesData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetPictures.php`, {
        id_std: id,
      })
        .then(res => {
          showPicture(res.data.picturedata);//alimente la gallery
          return;
        })
    } catch (error) { throw error; }
  };

  const getItemData = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          if (res.data.success === true) {
            /*  console.log(res.data.query)
             console.log(res.data.itemdata); */
            setArticle(res.data.itemdata);
            getPicturesData(sessionStorage.getItem("id_std"));//photo
            //setlibelle(res.data.libelle);
            //console.log(res.data.libelle)
            return;
          }
          else {
            console.log('erreur');
            //console.log(res.data.query)
          }
        })
    } catch (error) { throw error; }
  };

  const verifProduct = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.post(`${ApiConfig.baseUrl}/Controls/VerifProduct.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {

          // console.log(res.data)
          if (res.data.success === true) {
            // if (res.data.itemdata.nivfin_stock >= 1){
            resultUpdateCheck(2);
            resultUpdateProduct('Votre article est validé');
            //getItemData();          
          }
          else if (res.data.success === false) {
            resultUpdateProduct('Il manque une information dans votre fiche article, vérifiez une nouvelle fois');
            //getItemData();
            //resultUpdateProduct('Echec lors de la création de votre fiche article');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const sendMailForItem = async () => {
    try {
      Axios.post(`${ApiConfig.baseUrl}/Controls/SendMailForItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          if (res.data.success === true) {
            console.log('envoyé');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const addToProduct = async (event) => {
    try {
      event.preventDefault();
      event.persist();

      Axios.post(`${ApiConfig.baseUrl}/Controls/AddToProduct.php`, {
        //idColor: sessionStorage.getItem("getColorSelect"),
        idModele: sessionStorage.getItem("getModeleSelect"),
        idMateriau: sessionStorage.getItem("getMateriauSelect"),
        idFinition: sessionStorage.getItem("getFinitionSelect"),
        idFabricant: sessionStorage.getItem("getFabricantSelect"),
        /*   idFournisseur: sessionStorage.getItem("getFournisseurSelect"),
          idMarque: sessionStorage.getItem("getMarqueSelect"), */
        productId: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          //console.log(res)
          if (res.data.productupdatedata.success === true) {
            resultUpdateProductComplement('Votre produit est mis à jour');

            //getItemData();
          }
          else if (res.data.productupdatedata.success === false) {
            resultUpdateProductComplement('Echec de la Maj des caractéristiques');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const getItemRotate = async (id) => {
    //console.log(position.current.state.currentIndex)
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/RotatePicture.php`, {
        id_std: id,
        position: position.current.state.currentIndex,
      })
        .then(res => {
          // console.log(res.data)
          getItemData();
          return;
        })
    } catch (error) { throw error; }
  };

  /*  useEffect(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }); */

  return (
    <div>
      <Header
        color="black"
        brand="BOURSE DU MOBILIER"
        /* links={<HeaderLinks dropdownHoverColor="info" />} */
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "primary"
        }}
      />
      <br /><br />      <br /><br />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <br /><br /><br /><br /><br />
                <div className={classes.name} id="topFiche">
                  <h3 className={classes.title}>Partagez vos mobiliers</h3>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classes.textCenter}>
            <h4 className={classNames(classes.textBolder, classes.textBlue)}>
              Pour partagez votre mobilier c'est ici !
            </h4>
          </div>
          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="info"
              tabs={[
                {
                  tabButton: "Créez votre article",
                  tabIcon: ArtTrack,
                  tabContent: (
                    <div>
                      <form className={classes.form} onSubmit={addToCatalogue}>
                        <br />   <br />
                        <GridContainer>
                          <GridItem xs={10}
                            sm={10}
                            md={10}
                            className={classes.gridItem}>
                            <h4 className={classes.textCaramel}>Définissez la famille à laquelle votre article appartient</h4>
                            <SectionProductPage />
                            <br />   <br />
                            <h4 className={classes.textCaramel}>Définissez la couleur qui représente votre article</h4>
                            <label className={classes.title}>COULEUR</label>

                            <TextField className={classes.input}
                              type="text"
                              fullWidth
                              id="designationColor"
                              color="primary"
                              name="designationColor"
                              onChange={onChangeColor}
                              value={isColor}
                              autoComplete="off"
                              required
                            />
                            {/*  <SectionColor /> */}
                            <br />   <br />
                            <h4 className={classes.textCaramel}>Nommer votre article</h4>
                            <label className={classes.title}>NOM</label>
                            <TextField className={classes.input}
                              type="text"
                              fullWidth
                              id="designationLabel"
                              color="primary"
                              name="designationLabel"
                              onChange={onChangeLibelle}
                              value={islibelle}
                              autoComplete="off"
                              required
                            />

                            <p>Nombre de caractères: {text.length} - maximum 50 caractères</p>
                          </GridItem>
                        </GridContainer>
                        {/* <br /> <br /> */}
                        <GridContainer justify="center">
                          <GridItem
                            xs={10}
                            sm={10}
                            md={10}
                          >
                            <br /><br />
                            <h4 className={classes.textCaramel}>Indiquez les dimensions de votre article si vous les connaissez</h4>
                            <SectionSliderUnit />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.gridItem}
                          >

                            <div className={classes.textCenter}>
                              <h6 className={classes.textWarning}>{messageAddWarm}</h6>
                              {messageAdd == "" ? (
                                <Button color="info" round onClick={addToCatalogue}>Créez votre article

                                </Button>) : (
                                <h5 className={classNames(classes.textBolder, classes.textBlue)}>{messageAdd}</h5>
                              )}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                      <GridContainer justify="center">
                        <GridItem
                          xs={10}
                          sm={10}
                          md={10}
                          className={classes.gridItem}
                        >
                          <Muted>
                            <h4 className={classes.title}>Nom du produit</h4>
                          </Muted>
                          <br />
                          <h4 className={classNames(classes.textBolder, classes.textBlue)}>
                            {nameStd === "" ? ("Non défini") : (<></>)}

                            {nameStd}
                          </h4>
                        </GridItem>
                      </GridContainer>
                      <form className={classes.form} onSubmit={addDescription}>
                        <GridContainer justify="center">
                          <GridItem
                            xs={10}
                            sm={10}
                            md={10}
                            className={classes.gridItem}
                          >


                            <h4 className={classes.title}>Description de votre article</h4>

                            <TextareaAutosize className={classes.textAreaWithStyle}
                              type="text"
                              fullWidth
                              id="descriptionArea"
                              color="primary"
                              name="descriptionArea"
                              variant="standard"
                              onChange={onChangeValue}
                              label="Description"
                              placeholder="Décrivez votre produit..."
                              autoComplete="off"
                            />
                          </GridItem>
                        </GridContainer>
                        <br />
                        <GridContainer justify="center">
                          <GridItem
                            xs={10}
                            sm={10}
                            md={10}
                            className={classes.gridItem}
                          >
                            <Button
                              color="info"
                              size="sm"
                              round
                              onClick={addDescription}
                            >
                              Description{"\u00A0"} <SaveIcon />
                            </Button>
                            <br />
                            <h5 className={classNames(classes.textBolder, classes.textBlue)}>{messageDescription}</h5>
                          </GridItem>
                        </GridContainer>
                      </form>
                      <form className={classes.form} onSubmit={addToProduct}>
                        <br /><br />
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.gridItem}
                          >
                            <h4 className={classes.textCaramel}>Complétez votre fiche article avec l'aide des listes déroulantes ci-dessous</h4>
                            <h4 className={classes.title}>Complément d'information</h4>

                            <br />
                            <GridContainer className={classes.pickSize}>
                              <GridItem md={6} sm={6}>
                                <SectionModele />
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <SectionMateriau />
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <SectionFinition />
                              </GridItem>
                              {/*  <GridItem md={6} sm={6}>
                                <SectionFournisseur />
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <SectionMarque />
                              </GridItem> */}
                              <GridItem md={6} sm={6}>
                                <SectionFabricant />
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.gridItem}
                          >

                            <div className={classes.textCenter}>
                              <br />
                              <Button color="info" round onClick={addToProduct}>
                                ENREGISTRER
                              </Button>
                              <h6>{messageUpdateComplement}</h6>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>

                      <br></br>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={10}
                          className={classes.gridItem}
                        >
                          <h4 className={classes.textCaramel}>Chargez 1 à 3 photos de votre article</h4>
                          <br></br>
                          <h4 className={classes.title}>Photos taille maximum 1920 * 1080 jpg ou png</h4>

                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='1'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              />
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='2'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='3'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                      <br></br><br></br>
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          className={classes.gridItem}
                        >

                        </GridItem>
                      </GridContainer>
                      <br /><br />
                      <h4 className={classes.textCaramel}>Précisez le lieu ou se trouve votre article, enregistrez ce lieu</h4>
                      {/*     <br></br>
                            <h4 className={classes.title}>Localisation</h4> */}
                      <SectionLocalisation />
                      <GridContainer>
                        <GridItem xs={12}
                          sm={12}
                          md={12}>
                          <Card product plain>
                            <CardBody className={classes.textCenter} plain>
                              <div>
                                <h4 className={classes.textCaramel}>Indiquez le nombre d'article que vous cédez et enregistrez-le</h4>
                                <Compteur
                                  id_std={id_std_create}
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                      {/* <form className={classes.form} onSubmit={addToProduct}> */}
                      <br /><br />

                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.gridItem}
                        >

                          <div className={classes.textCenter}>
                            {messageCheck === 1 ? (
                              <Button color="primary" round onClick={verifProduct}>
                                Vérifier la fiche article
                              </Button>) : messageCheck === 2 ? (
                                <Button round color="danger"
                                  startIcon={<CachedIcon />}
                                  onClick={() => { resultUpdateCheck(3); sendMailForItem(); }}
                                >
                                  Valider le partage
                                </Button>
                              ) : messageCheck === 3 ? (

                                <>
                                  <Link to="/catalogue-page">
                                    <Button round color="info"
                                      startIcon={<ArrowForwardIcon />}
                                     >
                                      Catalogue
                                    </Button>
                                  </Link>
                                </>


                              ) : (<></>)}
                            <h6>{messageUpdate}</h6>
                          </div>
                        </GridItem>
                      </GridContainer>
                      {/*                       </form>
 */}
                      <br /><br />
                    </div>
                  )
                },
                /* {
                   tabButton: "Visualiser votre article",
                   tabIcon: Camera,
                   tabContent: (
                     // <GridContainer justify="center">
                     //   <GridItem xs={12} sm={12} md={6}>
 
                     <div className={classes.productPage}>
                       {isArticle.length === 0 ? (<>
                         <h4 className={classNames(classes.textBolder, classes.textBlue)}>Vous devez créer un article pour le visualiser</h4>
                       <div className={classes.textCenter}>
                               <br />                             
                               <Button round color="danger"
                                 startIcon={<CachedIcon />}
                                 onClick={e => { getItemData(); resetIndexData();}}>
                               Votre article
                               </Button>
 
                               <br />
                               <h6>{messageUpdate}</h6>
                             </div>  
                       </>) : (<>
                         {isArticle.map((item) => (
                           <div className={classes.main} key={item.id_std}>
                             <GridContainer>
                               <GridItem md={6} sm={6}>
                                 <ImageGallery
                                   showFullscreenButton={false}
                                   showPlayButton={false}
                                   startIndex={parseInt(sessionStorage.getItem("ctr_index"))}
                                   ref={position}
                                   items={images}
                                   showThumbnails={true}
                                   renderLeftNav={(onClick, disabled) => {
                                     return (
                                       <button
                                         className='image-gallery-left-nav'
                                         disabled={disabled}
                                         onClick={onClick}
                                       />
                                     );
                                   }}
                                   renderRightNav={(onClick, disabled) => {
                                     return (
                                       <button
                                         className='image-gallery-right-nav'
                                         disabled={disabled}
                                         onClick={onClick}
 
                                       />
                                     );
                                   }}
                                   renderCustomControls={(onClick) => {
                                     if (sessionStorage.getItem("statut_clt") === 'modeAdmin' || sessionStorage.getItem("statut_clt") === 'modeClient') {
                                       return (
                                         <Button
                                           color="transparent"
                                           size="sm"
                                           round
                                           onClick={() => getItemRotate(sessionStorage.getItem("id_std"))} >
                                           <RotateRightIcon /></Button>
                                       );
                                     }
                                   }}
                                 />
 
                               </GridItem>
                               <GridItem md={6} sm={6}>
                                 <h2 className={classes.title}>{item.libelle}</h2>
                                 <h3 className={classes.mainPrice}>{item.groupe}</h3>
                                 <Accordion
                                   active={0}
                                   activeColor="info"
                                   collapses={[
                                     {
                                       title: "Description",
                                       content: (
                                         <p> {item.description}
                                         </p>
                                       )
                                     }
                                   ]}
                                 />
                                 <Accordion
                                   active={0}
                                   activeColor="info"
                                   collapses={[
                                     {
                                       title: "Informations",
                                       content: (
                                         <ul>
                                           <li>Hauteur en cm : {item.unitheight}</li>
                                           <li>Largeur en cm : {item.unitwidth}</li>
                                           <li>Profondeur en cm : {item.unitdeph}</li>
                                           <li>Volume en m3 : {item.unitvolume}</li>
                                         </ul>
                                       )
                                     }
                                   ]}
                                 />
                                 <Accordion
                                   active={0}
                                   activeColor="info"
                                   collapses={[
                                     {
                                       title: "Complément",
                                       content: (
                                         <ul>
                                           <li>Couleur : {item.couleur}</li>
                                           <li>Materiau : {item.materiau}</li>
                                           <li>Finition : {item.finition}</li>
                                           <li>Modèle : {item.modele}</li>
                                           <li>Fabricant : {item.fabricant}</li>
                                         </ul>
                                       )
                                     }
                                   ]}
                                 />
                                 <br /><br />
                               </GridItem>
                             </GridContainer>
                           </div>
                         )
                         )}</>)}
                     </div>
                   )
                 }*/
              ]}
            />
          </div>
          <Clearfix />
        </div>
      </div>
      <br /><br />
    </div>
  );
}
