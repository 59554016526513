import React from "react";
import ApiConfig from "_config/ApiConfig";
import Button from "components/CustomButtons/Button.js";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from "react-router-dom";

export default function ToggleVisibilityOrder({ product }) {
    if (sessionStorage.getItem("statut_clt") === 'modeAdmin' || sessionStorage.getItem("statut_clt") === 'modeClient') {
        return (
            <>
                {/*  <Link to={{
        pathname: '/treatmentcartorder-page',
        search: '?productId='+product+'&mode=MAD'
       
      }}>COMMANDER</Link> */}

                <Link to="/treatmentcartorder-page">
                    <Button round color="info"
                        endIcon={<ArrowForwardIcon />}
                    >
                        COMMANDER
                    </Button>
                </Link>


               {/*  <a href={`${ApiConfig.baseUrl}/treatmentcartorder.php?productId=` + product + `&mode=MAD`}>
                    <Button round color="info"
                        endIcon={<ArrowForwardIcon />}
                    >
                        COMMANDER
                    </Button>
                </a> */}
            </>
        );
    }
    else {
        return (
            <>
                <Link to="/account-page">
                    <Button round color="info"
                        endIcon={<ArrowForwardIcon />}
                    >
                        INSCRIVEZ-VOUS
                    </Button>
                </Link>
            </>
        )
    }
}