/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

import { TextField } from '@material-ui/core/';

import SaveIcon from '@material-ui/icons/Save';

import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(profilePageStyle);

export default function SectionLocalisation() {

  const classes = useStyles();

  const history = useHistory();

  const [isNumber, setNumber] = useState();
  const [isRoad, setRoad] = useState();
  const [isStreet, setStreet] = useState();
  const [isCp, setCp] = useState();
  const [isBatiment, setBatiment] = useState();
  const [isRegion, setRegion] = useState();
  const [isReg, setIdReg] = useState();

  const [isListRegion, setListRegion] = useState([]);

  const [regionSelect, setRegionSelect] = useState([]);

  const onChangeRegionSelect = (e) => {
    setRegionSelect(e);//aliment la liste avec regionSelect
    setIdReg(e);//isReg est modifié et envoyé a axios
  }

  const [userInfo, setuserInfo] = useState({
    number: '',
    road: '',
    street: '',
    cp: '',
    batiment: '',
    region: '',
  });

  const onChangeValue1 = (e) => {
    setNumber(e.target.value);
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }

  const onChangeValue2 = (e) => {
    setRoad(e.target.value);
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }
  const onChangeValue3 = (e) => {
    setStreet(e.target.value);
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }
  const onChangeValue4 = (e) => {
    setCp(e.target.value);
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }
  const onChangeValue5 = (e) => {
    setBatiment(e.target.value);
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }

 const loadRegion = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetRegion.php`)
        .then(res => {
          setListRegion(res.data.listeregiondata);
          return;
        })
    } catch (error) { throw error; }
  };

  const getAdress = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetAdress.php`)
        .then(res => {
          if (res.data.success === true) {
            //console.log(res.data.query);
            setNumber(res.data.adressdata['number']);
            setRoad(res.data.adressdata['road']);
            setStreet(res.data.adressdata['street']);
            setCp(res.data.adressdata['cp']);
            setBatiment(res.data.adressdata['batiment']);
            setRegion(res.data.adressdata['departement']);
            setIdReg(res.data.adressdata['idReg']);
          }
          else {
            console.log('erreur');
          }
          return;
        })

    } catch (error) { throw error; }
  };



  const [messageUpdate, resultUpdateLocalization] = useState([]);
  const [message, resultLocalization] = useState([]);

  const addToLocalization = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      console.log(userInfo.number)
      console.log(userInfo.road)
      console.log(userInfo.street)
      console.log(userInfo.cp)
      console.log(userInfo.batiment)
      console.log(isReg)


      Axios.post(`${ApiConfig.baseUrl}/Controls/AddLocalisation.php`, {
        spotNumber: userInfo.number,
        spotRoad: userInfo.road,
        spotStreet: userInfo.street,
        spotCp: userInfo.cp,
        spotBatiment: userInfo.batiment,
        spotRegion: isReg,

        //id_pro: sessionStorage.getItem("getIdOwnerSelect"),
      })
        .then(res => {
          //console.log(res.data.mapdata)
          // resultLocalization(res.data.mapdata.message);

          if (res.data.mapdata.success === true) {
            resultLocalization('Localisation enregistrée');
            sessionStorage.setItem("idLoc", res.data.mapdata.idLoc);
          }
        })

    } catch (error) { throw error; }
  };

  if (sessionStorage.getItem("statut_clt") === 'modeAdmin' || sessionStorage.getItem("statut_clt") === 'modeClient') {

    useEffect(() => {
      getAdress();
    }, []);
    return (
      <div>
        <form className={classes.form} onSubmit={addToLocalization}>

          <h4 className={classNames(classes.textValidation, classes.textBolder)}>Adresse ou se trouve le produit</h4>
          <GridContainer>
            <GridItem md={3} sm={12}>
              <TextField className={classes.input}
                InputLabelProps={{ shrink: true }}
                type="text"
                id="number"
                name="number"
                variant="outlined"
                onChange={onChangeValue1}
                value={isNumber}
                label="Numéro"
                autoComplete="off"
                required
              />
            </GridItem>
            <GridItem md={3} sm={12}>
              <TextField className={classes.input}
                InputLabelProps={{ shrink: true }}
                type="text"
                id="road"
                name="road"
                variant="outlined"
                onChange={onChangeValue2}
                value={isRoad}
                label="Voie"
                autoComplete="off"
                required
              />
            </GridItem>
            <GridItem md={3} sm={12}>
              <TextField className={classes.input}
                InputLabelProps={{ shrink: true }}
                type="text"
                id="street"
                name="street"
                variant="outlined"
                onChange={onChangeValue3}
                value={isStreet}
                label="Ville"
                autoComplete="off"
                required
              />
            </GridItem>
            <GridItem md={3} sm={12}>
              <TextField className={classes.input}
                InputLabelProps={{ shrink: true }}
                type="text"
                id="cp"
                name="cp"
                variant="outlined"
                onChange={onChangeValue4}
                value={isCp}
                label="Code postal"
                placeholder="Code Postal"
                autoComplete="off"
                required
              />
            </GridItem>
          </GridContainer>
          <br></br>

          <GridContainer>
            <GridItem md={3} sm={12}>
              <TextField className={classes.input}
                InputLabelProps={{ shrink: true }}
                type="text"
                id="batiment"
                name="batiment"
                variant="outlined"
                onChange={onChangeValue5}
                value={isBatiment}
                label="Bâtiment"
                autoComplete="off"
                required
              />
            </GridItem>

            <GridItem md={3} sm={12}>
              {isListRegion.length === 0 ?
                (<>
                  <TextField className={classes.input}
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    id="region"
                    name="region"
                    variant="outlined"
                    onClick={loadRegion} //appele la liste des region et recupere l id
                    value={isRegion}
                    label="Région"
                    autoComplete="off"
                    required
                  />
                </>)
                : (<>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <Select defaultValue=""
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={regionSelect}
                      onChange={(event) => { onChangeRegionSelect(event.target.value) }}

                      inputProps={{
                        name: "regionSelect",
                        id: "regionSelect"
                      }}
                    >
                      {isListRegion.map((item) => (
                        <MenuItem key={item.id_reg}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={item.id_reg}
                        >
                          {item.departement}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>)
              }
            </GridItem>
            <GridItem md={3} sm={12} className={classes.textCenter}>
              <h4 className={classes.textCaramel}>Cliquez pour enregistrer ce lieu</h4>
              <Button color="info" size="sm" square="true" onClick={addToLocalization}>
                <SaveIcon fontSize="large" />
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem
              xs={12}
              sm={12}
              md={6}
              className={classes.gridItem}
            >
              <div className={classes.textCenter}>
                <br />
                <h6 className={classes.textBlue}>{message}</h6>
                <h6 className={classes.textWarning}>{messageUpdate}</h6>
              </div>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    );
  } else return false;
}
