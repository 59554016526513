
import React, { useEffect, useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";


import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";


const useStyles = makeStyles(styles);

export default function TooltipFavoris(props) {
  const classes = useStyles();

  const { id_std } = props;
  const { checkFav } = props;

  const [favoriState, setFavoriState] = useState(0);
  //console.log(favoriState)

  const favControl = async (id, flag) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/AddFavoris.php`, {
        id_std: id,
        fav: flag
      })
        .then(res => {
          /* console.log(res.data.fav)
          console.log(res.data.id) */
          setFavoriState(res.data.fav);
          return;
        })
    } catch (error) { throw error; }
  };

  useEffect(() => {
    setFavoriState(checkFav)
  }, []);


  return <>

    <Tooltip
      id="tooltip-top"
      title="Mettre en Favori"
      placement="left"
      classes={{ tooltip: classes.tooltip }}
    >
      {favoriState === 1 ? (
        <Button
          justIcon
          simple
          color="info"
          className={classes.pullRight}
          onClick={() => favControl(id_std, 2)}
        >
          <Favorite />
        </Button>
      ) : (
        <Button
          justIcon
          simple
          color="primary"
          className={classes.pullRight}
          onClick={() => favControl(id_std, 1)}
        >
          <Favorite />
        </Button>
      )}
    </Tooltip>
  </>
}