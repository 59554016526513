
import React from "react";
//import Axios from 'axios';
//import ApiConfig from "_config/ApiConfig";

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//import Button from "components/CustomButtons/Button.js";
//import Remove from "@material-ui/icons/Remove";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";

import DefaultConfirm from "components/ToggleVisibility/DefaultConfirm.js";

import ToggleVisibilityConfirm from "components/ToggleVisibility/ToggleVisibilityConfirm.js";
//const useStyles = makeStyles(styles);

export default function KillItem(props) {
  //const classes = useStyles();
 // const [messageEmptyLocalization, resultEmptyLocalization] = useState([]);
  const { id_item } = props
  
  if (!id_item === 0 && sessionStorage.getItem("statut_clt") === 'modeAdmin') {

    return <>
<br /><br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <span key={1}>
            <ToggleVisibilityConfirm>
              <DefaultConfirm 
              id_value={id_item }/>
            </ToggleVisibilityConfirm>
          </span>
        </GridItem>
      </GridContainer>
    </>
  }
  else return false;
}
