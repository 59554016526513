import {
  section,
  container,
  cardTitle,
  whiteColor,
  grayColor,
  infoColor,
  primaryColor,
  eflexTextColor,
  mlvTextColor,
  blackColor,
  mlAuto,
  hexToRgb,
  main,
  mainRaised,
  title,
  dangerColor
} from "assets/jss/material-kit-pro-react.js";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";

const styles = {
  // cardTitle,
  main,
  ...imagesStyles,
  ...customSelectStyle,
  ...tooltipsStyle,
  container: {
    ...container,
    zIndex: 2
  },
  section: {
    ...section,
    padding: "70px 0px"
  },
  mainRaised: {
    ...mainRaised
  },
  title: {
    ...title,
    marginBottom: 0
  },
 
  mainPrice: {
    margin: "10px 0px 25px"
  },
  cardDescription: {
    color: grayColor[0],
    textAlign: "center"
  },
  mlAuto,
  priceContainer: {
    display: "inline-flex"
  },
  price: {
    fontSize: "18px",
    color: grayColor[22]
  },
  priceOld: {
    fontSize: "16px",
    textDecoration: "line-through"
  },
  priceNew: {
    fontWeight: "500",
    color: infoColor[0]
  },
  stats: {
    color: grayColor[0]
  },
  textCenter: {
    textAlign: "center!important"
  },
  textJustify: {
    textAlign: "justify"
  },
  textLeft: {
    textAlign: "left!important"
  },
  cardBodyRefine: {
    paddingLeft: "15px",
    paddingRight: "15px"
  },
  features: {
    paddingTop: "30px"
  },
  productPage: {
    backgroundColor: whiteColor,
    "& $mainRaised": {
      margin: "-40vh 0 0",
      padding: "40px"
    },
    "& .image-gallery-slide img": {
      borderRadius: "10px",
      maxWidth: "450px",
      height: "auto"
    },
    "& .image-gallery-swipe": {
      margin: "42px 0px",
      overflow: "hidden",
      width: "100%",
      height: "auto",
      textAlign: "center"
    },
    "& .image-gallery-thumbnails > .image-gallery-thumbnails-container .image-gallery-thumbnail": {
      "&.active > .image-gallery-thumbnail-inner": {
        opacity: "1",
        borderColor: grayColor[6]
      },
      "& > .image-gallery-thumbnail-inner": {
        width: "80%",
        maxWidth: "85px",
        margin: "0 auto",
        padding: "8px",
        display: "block",
        border: "1px solid transparent",
        background: "transparent",
        borderRadius: "3px",
        opacity: ".8"
      },
      "& > .image-gallery-thumbnail-inner img": {
        borderRadius: "3px",
        width: "100%",
        height: "auto",
        textAlign: "center"
      }
    }
  },
  titleRow: {
    marginTop: "-8vh"
  },
  floatRight: {
    float: "right!important"
  },
  pickSize: {
    marginTop: "50px"
  },
  pullRight: {
    float: "right"
  },
  cardCategory: {
    textAlign: "center",
    marginTop: "10px"
  },
  cardTitle: {
    ...cardTitle,
    textAlign: "center"
  },
  // cardDescription: {
  //   textAlign: "center",
  //   color: grayColor[0]
  // },
  
  justifyContentBetween: {
    justifyContent: "space-between!important"
  },
  img: {
    width: "20%",
    marginRight: "5%",
    marginBottom: "5%",
    float: "left"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    top: "3px",
    width: "18px",
    height: "18px",
    position: "relative"
  },
  shadowForCard: {
    borderRadius: "10px",
    boxShadow: "0 3px 5px rgba(" + hexToRgb(grayColor[9]) + ",0.3)"
  },
  shadowForPicture: {
    borderRadius: "10px",
    boxShadow: "0 1px 5px rgba(" + hexToRgb(grayColor[9]) + ",0.2)"
  },
  textBlue: {
    fontWeight: "500",
    color: infoColor[0]
  },
  textWarning: {
    fontWeight: "500",
    color: dangerColor[0]
  },
  textWhite: {
    color: whiteColor
  },
  textEflex:{
    color:eflexTextColor
  },
  textMLV:{
    color:mlvTextColor
  },
  textBolder:{
    fontWeight: "600",
  },
  textSemiBolder: {
    fontWeight: "500",
  },
  textSemiBolderLight: {
    fontWeight: "400",
  },
  line: {
    textDecoration: "line-through"
   
  },
  sectionGray: {
    background: whiteColor

 },
 buttonEflex: {
  background: primaryColor[0],
  color: whiteColor
},
buttonBleu: {
  background: grayColor[1],
  color: whiteColor
},

containerForm: {
  width: "50%",
},

shadowForBox: {
borderRadius: "6px",
  boxShadow:
    "0 16px 24px 2px rgba(" +
    hexToRgb(blackColor) +
    ", 0.14), 0 6px 30px 5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)"
},
shadowForText: {
  textShadow: "0 3px 5px rgba(" + hexToRgb(blackColor) + ",0.3)"
},

textWithSmallShade: {
  textShadow: "0 0.0325rem 0.0525rem rgba(0, 0, 0, 0.2)"
},

contenerMap: {
  borderRadius: "6px",
  boxShadow:
    "0 16px 24px 2px rgba(" +
    hexToRgb(blackColor) +
    ", 0.14), 0 6px 30px 5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)"
},
/* elementPadding: {
  paddingTop: "40px"
}, */
elementRight: {
  paddingRight: "20px"
},
elementPadding: {
  paddingLeft: "20px",
  paddingRight: "20px"
},
breakLine: {
  whiteSpace: "pre-line",
  verticalAlign: "bottom",
},
textSize: {
  textTransform : "capitalize",
  fontSize: "18px"
},
spacePaddingS: {
  paddingBottom : "20px",
},
spacePaddingM: {
  paddingBottom : "50px",
},
spacePaddingL: {
  paddingBottom : "100px",
},
spacePaddingXL: {
  paddingBottom : "200px",
},
imageFit :{ margin: "auto",
  /* width: "350px", */
  height: "500px",
  /* objectFit: "contain", display: "flex", */
  /* backgroundColor: whiteColor, */
 
},
imageFit2 :{

  height: "100%",
 
  objectFit: "contain",  //ou cover pour remplir le div
  
},
inputtypecheckbox: {
  width:"1.8em",
  height: "1.8rem",
  accentColor: "#BF7B1B",
},
checkedIcon: {
  width: "20px",
  height: "20px",
  border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.84)",
  borderRadius: "3px"
},
uncheckedIcon: {
  width: "0px",
  height: "0px",
  padding: "9px",
  border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
  borderRadius: "3px"
},
smallTextSize: {
  fontSize: "14px",
  lineHeight: "1.42857",
  color: "#aaaaaa",
  fontWeight: 400,
},
};

export default styles;
