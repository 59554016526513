/*eslint-disable*/
import React, { useEffect, useState } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import classNames from "classnames";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";

// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import Email from "@material-ui/icons/Email";

// import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import { Link } from "react-router-dom";
import { TextField } from '@material-ui/core/';
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import accountPageStyle from "assets/jss/material-kit-pro-react/views/accountPageStyle.js";

import image from "assets/img/smartphone.jpg";

const useStyles = makeStyles(accountPageStyle);

export default function LoginPage() {


  const classes = useStyles();
  const history = useHistory();
  
  
  const [message, resultAddAccount] = useState([]);

  const [isClientValidation, setClientValidation] = useState([]);
  const [clientSelect, setCompteSelect] = useState([]);



  const [accountValidate, setAccountValidate] = useState(false);

  const getClientValidation = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetClientValidation.php`)
        .then(res => {
          console.log(res.data.listclientdata)
          setClientValidation(res.data.listclientdata);
          //setAccountValidate(false);
          return;
        })
    } catch (error) { throw error; }
  };


  const submitUser = async (event) => {
    // console.log(clientSelect)
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/ValideUser.php`, {
        client: clientSelect,
      })
        .then(res => {
          if (res.data.uservalidedata['success'] === true) {
            setAccountValidate(res.data.uservalidedata['email']);
            resultAddAccount('');
            getClientValidation();
          } else {
            resultAddAccount(res.data.uservalidedata['msg']);
          }
          return;
        })
    } catch (error) { throw error; }
  };
  useEffect(() => {
    getClientValidation();
  }, []);
  return (
    <div>
      <Header
        absolute
        color="white"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <form className={classes.form} onSubmit={submitUser}>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Validation de compte</h4>
                  </CardHeader>

                  <CardBody signup>



                    <br /><br />
                    <h4 className={classNames(classes.textValidation, classes.textBolder)}>Sélectionnez un compte en attente de validation dans la liste</h4>

                    <br /><br />
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select defaultValue=""
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={clientSelect}
                        onChange={(event) => { setCompteSelect(event.target.value) }}

                        inputProps={{
                          name: "clientSelect",
                          id: "clientSelect"
                        }}
                      >
                       
                         {isClientValidation.map((item) => (
                          <MenuItem key={item.id_clt}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={item.id_clt}
                          >
                            {item.nom} {item.prenom} | {item.email} | {item.entreprise} | {item.batiment}
                          </MenuItem>
                        ))}
                      
                      </Select>
                    </FormControl>
                   
                    <br /><br />

                  </CardBody>
                  <div className={classes.textCenter}>
                    {accountValidate === false ?
                      <>
                        
                        
                        <h6 className={classes.textValidation}>Vous validez l'accès pour ce compte en cliquant sur le bouton "Enregister"</h6>
                        <br />
                        <Button type="submit" color="info" size="sm">Enregister</Button>
                      </>
                      :
                      <>
                        <h6 className={classes.textValidation}>Le compte {accountValidate} est validé</h6>
                        <Button round className={classes.buttonEflex}
                          onClick={() => setAccountValidate(false)}>
                          Cliquez ici pour confirmer
                        </Button>
                      </>
                    }
                    <br />
                    <br />
                    <h6 className={classes.textWarning}>{message}</h6>
                  </div>
                </form>
                <br />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <br /><br />
      </div>
    </div>
  );
}
