import React from "react";
import { useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

export default function LoadItem(props) {

  const history = useHistory();
  const { id_item } = props
  const handleClick = function (e) {
    e.preventDefault()
    history.push("/catalogue-page");
  }

  if (!id_item === 0) {
    return <>
      <GridContainer>
        <GridItem xs={4} sm={4} md={4}>
          <span key={1}>
            <Button
              color="tumblr"
              size="sm"
              round
              onClick={handleClick}>
              Continuer vers l'inventaire 
            </Button>
          </span>
        </GridItem>
      </GridContainer>
    </>
  }
  else return false;
}
